import http from './httpService';

export const getPointClouds = () => {
    return http.get(
        `${process.env.REACT_APP_MODELIC_API}/viewer/pointcloud/download`
    );
};

export const uploadPointCloud = (file, config) => {
    return http.post(
        `${process.env.REACT_APP_MODELIC_API}/viewer/pointcloud/upload`,
        file,
        config
    );
};

export const deletePointCloud = (data) => {
    return http.delete(
        `${process.env.REACT_APP_MODELIC_API}/viewer/pointcloud/delete`,
        { data }
    );
};

export const getPointCloud = (url, config) => {
    return http.get(url, config);
};

export const analyzePointCloud = (body, modelId, index) => {
    return http.post(
        `${process.env.REACT_APP_OSS_API}/pointcloud/analyze/${modelId}/${index}`,
        body
    );
};

export const cancelAnalysis = (body, modelId) => {
    return http.post(
        `${process.env.REACT_APP_OSS_API}/pointcloud/analyze/cancel/${modelId}`,
        body
    );
};

