import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { mainContext } from '../../context/mainContext';
import { IcHome } from '../../../share/icons';
import { clearViewer } from '../../../actions/viewer';
import locale from '../../../utils/localization.json';
import BreadcrumbItem from './BreadcrumbItem';

const Breadcrumb = ({ location }) => {
    const { category_name, project_name, logo_url } = useSelector(
        (state) => state.currentProject
    );
    const { name } = useSelector((state) => state.currentObject);
    const lang = useSelector((state) => state.language);

    const { removeAllPointClouds } = useContext(mainContext);

    const dispatch = useDispatch();

    return (
        <div className="justify-start items-center gap-0.5 flex">
            <BreadcrumbItem link="/dashboard">
                <div
                    onClick={() => {
                        removeAllPointClouds();
                        dispatch(clearViewer());
                    }}
                    className="mr-1"
                >
                    {logo_url ? (
                        <img src={logo_url} alt="" className="h-8" />
                    ) : (
                        <div className="scale-150">{IcHome}</div>
                    )}
                </div>
            </BreadcrumbItem>
            <BreadcrumbItem link="/dashboard/category" isSub>
                {category_name || 'category'}
            </BreadcrumbItem>
            <BreadcrumbItem link="/dashboard/project" isSub>
                {project_name || 'project'}
            </BreadcrumbItem>
            {location.pathname.includes('viewer') ? (
                <>
                    <BreadcrumbItem link="/" isSub>
                        {location.pathname.includes('pointclouds')
                            ? locale[lang].pointClouds
                            : locale[lang].models}
                    </BreadcrumbItem>
                    <BreadcrumbItem isSub>{name}</BreadcrumbItem>
                </>
            ) : (
                <BreadcrumbItem isSub>
                    {location.pathname.includes('pointclouds')
                        ? locale[lang].pointClouds
                        : locale[lang].models}
                </BreadcrumbItem>
            )}
        </div>
    );
};

export default withRouter(Breadcrumb);
