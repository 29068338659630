import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Bimodel from './Bimodel';
import PageLoader from './../components/common/PageLoader';
import { setToken } from './../actions/token';
import { deleteExpiredCache } from './../utils/cache';
import '../styles/globals.css';

const App = () => {
    const currentProject = useSelector((state) => state.currentProject);
    const token = useSelector((state) => state.token);

    const dispatch = useDispatch();

    const location = useLocation();

    // setTimeout(() => {
    //     throw new Error("Sentry Test Error");
    //   });

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (!token) dispatch(setToken(searchParams.get('viewer_token')));

        deleteExpiredCache();
    });

    return (
        <>
            {isEmpty(currentProject) ? <PageLoader /> : <Bimodel />}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    );
};

export default App;
