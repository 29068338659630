import { setCurrentProject } from './project';
import http from '../services/httpService';

export const setToken = (token) => {
    return async (dispatch) => {
        if (token) {
            http.headers.common['X-Viewer-Token'] = token;
            await dispatch({ type: 'SET_TOKEN', payload: token });
            await dispatch(setCurrentProject());
        } else {
            window.location.replace(
                `${process.env.REACT_APP_MODELIC_REDIRECT}`
            );
        }
    };
};
