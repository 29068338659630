import { updateNotifications } from '../actions/viewer';
import locale from './../utils/localization.json';

export const fetchAndCacheFile = async (
    url,
    signal,
    dispatch,
    filename,
    name,
    lang,
    setLoadedPointClouds
) => {
    const cacheName = 'minio-cache';
    const cache = await caches.open(cacheName);

    // Check if file is in cache
    const urlCache = url.split('?')[0];
    let cachedResponse = await cache.match(urlCache);
    if (cachedResponse) {
        return await cachedResponse.text();
    }

    // Fetch from MinIO and cache the response
    const response = await fetch(url, { signal });
    cachedResponse = response.clone();

    const reader = response.body?.getReader();

    if (!reader) {
        return;
    }

    const contentLength = +(response.headers?.get('Content-Length') || 0);
    let receivedLength = 0;
    let chunks = [];
    let progress = 0;
    let preProgress;

    while (true) {
        if (preProgress !== progress) {
            await dispatch(
                updateNotifications(
                    filename,
                    `${locale[lang].pointCloudNotification} "${name}" : ${progress} %`
                )
            );
            preProgress = progress;
        }

        const { done, value } = await reader.read();

        if (done) {
            break;
        }

        chunks.push(value);
        receivedLength += value.length;

        progress = Math.floor((receivedLength / contentLength) * 100);

        if (preProgress !== progress && progress < 100) {
            dispatch(
                updateNotifications(
                    filename,
                    `${locale[lang].pointCloudNotification} "${name}" : ${progress} %`
                )
            );

            const _progress = progress;

            setLoadedPointClouds((prev) => {
                const updatedLoadedPointClouds = [...prev];
                const index = updatedLoadedPointClouds.findIndex(
                    (p) => p.filename === filename
                );

                updatedLoadedPointClouds[index].progress = _progress;
                return [...updatedLoadedPointClouds];
            });

            preProgress = progress;
        }
    }

    let chunksAll = new Uint8Array(receivedLength);
    let position = 0;
    for (let chunk of chunks) {
        chunksAll.set(chunk, position);
        position += chunk.length;
    }

    const data = new TextDecoder('utf-8').decode(chunksAll);

    if (response.ok) {
        localStorage.setItem('Modelic.' + urlCache, Date.now());
        await cache.put(urlCache, cachedResponse); // Store file
    }

    return data;
};

export const deleteCache = async (url) => {
    const cacheName = 'minio-cache';
    const cache = await caches.open(cacheName);

    // Delete if file is in cache
    const urlCache = url.split('?')[0];
    await cache.delete(urlCache);
    localStorage.removeItem('Modelic.' + urlCache);
};

export const deleteExpiredCache = async () => {
    const cacheName = 'minio-cache';
    const cache = await caches.open(cacheName);
    const keys = await cache.keys();
    keys.forEach(({ url }) => {
        const date = Number(localStorage.getItem('Modelic.' + url));

        // delete cache after 14 days
        if (Date.now() > date + 1000 * 60 * 60 * 24 * 14) deleteCache(url);
    });

    for (const key in localStorage) {
        // delete from localSotrage after 14 days
        if (
            key.includes('Modelic') &&
            Date.now() >
                Number(localStorage.getItem(key)) + 1000 * 60 * 60 * 24 * 14
        )
            localStorage.removeItem(key);
    }
};
