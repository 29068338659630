import { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import {
    IcFilter,
    IcFilterFill,
    IcIssue,
    IcIssueFill,
    IcModel,
    IcModelFill,
    IcPointCloud,
    IcRefresh,
    IcView,
    IcViewFill,
} from './../../share/icons';
import Toolbar from '../../share/components/Toolbar';
import { mainContext } from './../context/mainContext';
import ToolContainer from './ToolContainer';
import Object from './Object';
import View from './View';
import Filter from './Filter';
import Issue from './Issue';
import { showIssues } from './../../actions/issue';
import IssueContext from './../context/IssueContext.jsx';
import { setCloseToolbar, setMarkupsToolbar } from '../../actions/toolbar.js';
import locale from '../../utils/localization.json';
import { getAllModels } from '../../actions/model.js';
import { getAllPointClouds } from './../../actions/pointcloud';

const ToolbarContainer = () => {
    const lang = useSelector((state) => state.language);
    const currentObject = useSelector((state) => state.currentObject);
    const { permissions } = useSelector((state) => state.currentProject);
    const viewer = useSelector((state) => state.viewer);
    const issuesVisible = useSelector((state) => state.issuesVisible);
    const { markupsToolbar, closeToolbar } = useSelector(
        (state) => state.toolbars
    );

    const dispatch = useDispatch();

    const { loadModel } = useContext(mainContext);

    useEffect(() => {
        if (!issuesVisible) {
            if (!isEmpty(viewer)) {
                let markupExt = viewer?.getExtension(
                    'Autodesk.Viewing.MarkupsCore'
                );

                markupExt?.leaveEditMode();
                markupExt?.hide();
            }

            markupsToolbar?.setVisible(false);
            document.getElementById('markupsToolbar')?.remove();
            dispatch(setMarkupsToolbar());

            closeToolbar?.setVisible(false);
            document.getElementById('closeMarkupsToolbar')?.remove();
            dispatch(setCloseToolbar());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issuesVisible]);

    const tools = [];
    tools.push({
        id: 'models',
        icon: IcModel,
        activeIcon: IcModelFill,
        heading: locale[lang].models,
        component: (
            <ToolContainer>
                <Object isModel={true} />
            </ToolContainer>
        ),
        callback: () => dispatch(showIssues(false)) && dispatch(getAllModels()),
    });
    if (permissions?.point_cloud)
        tools.push({
            id: 'pointclouds',
            icon: IcPointCloud,
            heading: locale[lang].pointClouds,
            component: (
                <ToolContainer>
                    <Object />
                </ToolContainer>
            ),
            callback: () =>
                dispatch(showIssues(false)) && dispatch(getAllPointClouds()),
        });
    tools.push({
        id: 'views',
        icon: IcView,
        activeIcon: IcViewFill,
        heading: locale[lang].views,
        component: (
            <ToolContainer>
                <View />
            </ToolContainer>
        ),
        callback: () => dispatch(showIssues(false)),
    });
    if (permissions?.issue)
        tools.push({
            id: 'issues',
            icon: IcIssue,
            activeIcon: IcIssueFill,
            heading: locale[lang].issues,
            component: (
                <ToolContainer>
                    <IssueContext>
                        <Issue />
                    </IssueContext>
                </ToolContainer>
            ),
            callback: () => dispatch(showIssues(true)),
        });
    tools.push({
        id: 'filter',
        icon: IcFilter,
        activeIcon: IcFilterFill,
        heading: locale[lang].filter,
        component: (
            <ToolContainer>
                <Filter />
            </ToolContainer>
        ),
        callback: () => dispatch(showIssues(false)),
    });
    tools.push({
        id: 'refresh',
        icon: IcRefresh,
        callback: () => {
            if (!isEmpty(viewer)) loadModel(viewer, currentObject, 0);

            const close = document.getElementById('toolbar-close');
            if (!close?.disabled) close.click();
        },
    });
    return (
        <div className="fixed top-0">
            <Toolbar tools={tools} />
        </div>
    );
};

export default ToolbarContainer;
