import React from 'react';
import { Link } from 'react-router-dom';

import { IcSubMenu } from './../../../share/icons';

const BreadcrumbItem = ({ isSub, link, children }) => {
    return (
        <>
            {isSub && (
                <div className="text-modelic-gray-500 scale-150 mx-2">
                    {IcSubMenu}
                </div>
            )}
            {link ? (
                <Link
                    to={link}
                    className="text-modelic-gray-500 text-base font-normal font-poppins uppercase hover:text-modelic-gray-100"
                >
                    {children}
                </Link>
            ) : (
                <div className="text-modelic-gray-500 text-base font-normal font-poppins uppercase">
                    {children}
                </div>
            )}
        </>
    );
};

export default BreadcrumbItem;
