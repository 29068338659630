import { createContext } from 'react';

export const mainContext = createContext({
    handleTranslateModel: () => {},
    lunchViewer: () => {},
    loadModel: () => {},
    validator: null,
    currentLinks: [],
    setCurrentLinks: () => {},
    loadedPointClouds: [],
    setLoadedPointClouds: () => {},
    removePointCloud: () => {},
    removeAllPointClouds: () => {},
});
