import axios from 'axios';
import { errorMessage } from '../utils/message';
import locale from '../utils/localization.json';
import log from '../utils/log';

const lang = localStorage.getItem('language');

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

axios.interceptors.response.use(null, (err) => {
    if (!err.message.includes('cancel')) {
        if (err.response?.data?.redirectUrl) {
            window.location.replace(err.response.data.redirectUrl);
        } else if (err.response?.data?.message === 'cookies not found!') {
            window.location.replace(
                `${process.env.REACT_APP_MODELIC_REDIRECT}/signin`
            );
        } else {
            const expectedErrors =
                err.response?.status >= 400 && err.response?.status < 500;

            if (err.response?.status === 401)
                window.location.replace(
                    `${process.env.REACT_APP_MODELIC_REDIRECT}/signin`
                );
            else if (err.response?.status === 422)
                err.response?.data?.detail.map((error) =>
                    errorMessage(`${error.msg.toUpperCase()} : ${error.loc[1]}`)
                );
            else if (
                err.response?.status === 409 &&
                err.response?.data?.title === 'Analysis'
            )
                errorMessage(locale[lang].analysisError);
            else if (expectedErrors) {
                errorMessage(
                    err.response?.data?.detail?.error ||
                        err.response?.data?.detail ||
                        err.response?.data?.message ||
                        locale[lang].error
                );
                log.error(err);
            } else {
                errorMessage(locale[lang].error);
                log.error(err);
            }
        }
    }

    return Promise.reject(err);
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    headers: axios.defaults.headers,
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
};
