import React from 'react';
import Breadcrumb from './Navigation/Breadcrumb';

const TopBar = () => {
    return (
        <div className="w-full flex-col justify-start items-center inline-flex">
            <div className="w-full h-12 justify-between items-center inline-flex">
                <Breadcrumb />
            </div>
        </div>
    );
};

export default TopBar;
