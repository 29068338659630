import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'underscore';

import { getAllLeafComponents } from '../../utils/forge';
import { IcSelectArrow } from './../../share/icons';
import locale from '../../utils/localization.json';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

const Filter = () => {
    const [properties, setProperties] = useState([]);
    const [selectedPropery, setSelectedPropery] = useState();
    const [values, setValues] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const lang = useSelector((state) => state.language);
    const viewer = useSelector((state) => state.viewer);

    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedPropery('Category');
        return () => {
            viewer?.isolate();
            dispatch(hideLoading('viewer'));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewer]);

    useEffect(() => {
        setValues([]);
        setSelectedValue(null);
        if (selectedPropery)
            getAllLeafComponents(viewer, async (dbIds) => {
                const t = Math.ceil(dbIds.length / 1000); // 100 -> to prevent out of memory error

                let props = [];
                let vals = [];

                for (let i = 0; i < t; i++) {
                    const start = i * 1000;
                    const end = Math.min((i + 1) * 1000, dbIds.length);

                    try {
                        let dbIdsPart = dbIds.slice(start, end);

                        const items = await viewer?.model?.getPropertySetAsync(
                            dbIdsPart
                        );

                        Object.entries(items.map).forEach(([, p]) => {
                            const property = p[0].displayName;

                            if (!props.includes(property)) props.push(property);

                            if (property === selectedPropery) {
                                const value = [
                                    ...new Set(
                                        p.map(
                                            ({ displayValue }) => displayValue
                                        )
                                    ),
                                ];

                                vals.push(...value);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        break;
                    }
                }

                setProperties(_.sortBy(props));
                setValues([...new Set(vals)]);
            });
    }, [selectedPropery, viewer]);

    useEffect(() => {
        if (selectedValue) {
            dispatch(showLoading('viewer'));
            getAllLeafComponents(viewer, async (dbIds) => {
                const t = Math.ceil(dbIds.length / 1000); // 100 -> to prevent out of memory error

                let elements = [];

                for (let i = 0; i < t; i++) {
                    const start = i * 1000;
                    const end = Math.min((i + 1) * 1000, dbIds.length);

                    try {
                        let dbIdsPart = dbIds.slice(start, end);

                        const items = await viewer?.model?.getPropertySetAsync(
                            dbIdsPart
                        );

                        Object.entries(items.map).forEach(([, p]) => {
                            const property = p[0].displayName;

                            if (property === selectedPropery) {
                                elements.push(
                                    ...p
                                        .filter(
                                            ({ displayValue }) =>
                                                displayValue === selectedValue
                                        )
                                        .map(({ dbId }) => dbId)
                                );
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        break;
                    }
                }

                viewer?.setGhosting(false);
                viewer?.isolate(elements);

                dispatch(hideLoading('viewer'));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPropery, selectedValue, viewer]);

    const handleSelectPropertyChange = async (e) => {
        setSelectedPropery(e.target.value);
    };

    return (
        <div className="flex-col inline-flex gap-6 w-full">
            <div className="relative">
                <label
                    htmlFor="properties"
                    className="text-modelic-gray-300 block mb-1 text-xs font-normal font-poppins"
                >
                    {locale[lang].properties}
                </label>
                <div className="absolute right-4 top-8 text-modelic-gray-500">
                    {IcSelectArrow}
                </div>
                <select
                    id="properties"
                    className="truncate bg-modelic-gray-900 block w-full pl-3 pr-8 py-2 outline-0 text-sm font-normal font-poppins text-modelic-gray-500 border border-modelic-gray-600 rounded"
                    onChange={handleSelectPropertyChange}
                    defaultValue={selectedPropery}
                >
                    <option value={selectedPropery}>{selectedPropery}</option>
                    {properties.map((prop, index) => (
                        <option key={index} value={prop}>
                            {prop}
                        </option>
                    ))}
                </select>
            </div>
            {selectedPropery && (
                <div className="relative">
                    <label
                        htmlFor="values"
                        className="text-modelic-gray-300 block mb-1 text-xs font-normal font-poppins"
                    >
                        {locale[lang].values}
                    </label>
                    <div className="absolute right-4 top-8 text-modelic-gray-500">
                        {IcSelectArrow}
                    </div>
                    <select
                        id="values"
                        className="truncate bg-modelic-gray-900 block w-full pl-3 pr-8 py-2 outline-0 text-sm font-normal font-poppins text-modelic-gray-500 border border-modelic-gray-600 rounded"
                        value={
                            selectedValue !== null
                                ? selectedValue
                                : locale[lang].selectValue
                        }
                        onChange={(e) => setSelectedValue(e.target.value)}
                    >
                        <option value={locale[lang].selectValue} disabled>
                            {locale[lang].selectValue}
                        </option>
                        {values.map((value, index) => (
                            <option key={index} value={value}>
                                {value}
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    );
};

export default Filter;
