import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Information from './Information';
import Dropzone from './../../share/components/CDN/Dropzone';
import { handleModelUpload } from '../../actions/model';
import { uploadNewPointCloud } from '../../actions/pointcloud';
import locale from '../../utils/localization.json';

const Content = ({ children, location }) => {
    const { permissions } = useSelector((state) => state.currentProject);
    const language = useSelector((state) => state.language);

    const [isLoading, setLoading] = useState(false);
    const [hasAccess, setHasAccess] = useState();
    const [accept, setAccept] = useState();
    const [lang, setLang] = useState();

    const dispatch = useDispatch();

    const modelUploadHandler = async (body, callback) => {
        await dispatch(handleModelUpload(body, callback));
    };

    const pointcloudUploadHandler = async (body, callback) => {
        await dispatch(uploadNewPointCloud(body, callback));
    };

    useEffect(() => {
        setLoading(false);
    }, [isLoading]);

    useEffect(() => {
        if (
            (location.pathname.includes('/models') &&
                permissions?.model?.upload) ||
            (location.pathname.includes('/pointclouds') &&
                permissions?.point_cloud?.upload)
        )
            setHasAccess(true);
        else setHasAccess(false);

        if (location.pathname.includes('/models')) {
            setAccept({ 'application/octet-stream': ['.rvt'] });

            setLang({
                dropzone: {
                    title: locale[language].dragDrop,
                    or: locale[language].or,
                    chooseFile: locale[language].chooseFile,
                    types: 'RVT',
                },
            });
        } else {
            setAccept({
                'application/octet-stream': ['.e57'],
                'text/plain': ['.txt'],
            });

            setLang({
                dropzone: {
                    title: locale[language].dragDrop,
                    or: locale[language].or,
                    chooseFile: locale[language].chooseFile,
                    types: 'E57 & TXT',
                },
            });
        }
    }, [language, location, permissions?.model, permissions?.point_cloud]);

    return (
        <>
            <div className="w-full h-[calc(100%-40px)] items-start flex flex-col lg:flex-row gap-6 lg:inline-flex">
                <div className="xl:w-[23%] lg:w-1/4 lg:flex-col sm:flex sm:flex-row flex-col w-full justify-start items-start gap-6 inline-flex">
                    <Information />
                    {!isLoading && hasAccess && (
                        <div className="h-full w-full p-3 rounded-md bg-modelic-gray-900 text-modelic-gray-100">
                            <Dropzone
                                upload={{
                                    uploadHandler: location.pathname.includes(
                                        '/models'
                                    )
                                        ? modelUploadHandler
                                        : pointcloudUploadHandler,
                                    loading: () => {
                                        setLoading(true);
                                    },
                                    downloadHandler: () => {},
                                }}
                                accept={accept}
                                maxSize={
                                    process.env.REACT_APP_CDN_MAX_SIZE || 200
                                }
                                maxFiles={
                                    location.pathname.includes('/models')
                                        ? 3
                                        : 1
                                }
                                lang={lang}
                            />
                        </div>
                    )}
                </div>
                <div className="h-full w-full max-w-full lg:w-auto grow justify-center items-start inline-flex">
                    {children}
                </div>
            </div>
        </>
    );
};

export default withRouter(Content);
